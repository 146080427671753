import { trackSignUpButtonClicked } from '@/common/analytics';
import { Button, H2, useSearchParams } from '@cointracker/ui';
import { button } from '@cointracker/ui/src/components/base/Button/Button.css';
import classNames from 'classnames';
import type { getHeroProps } from '../Landing';
import { getStartedButton, glow, glowAlways } from '../Landing.css';
import { usePartnerStore } from '../partnerStore';
import { buildGetStartedUrl, getNextUrl } from '../utils';
import { container, subtitle, title } from './FooterPitch.css';

export const FooterPitch = ({
  registerButtonTitle,
  discountText,
  toggleModal,
}: getHeroProps) => {
  const [searchParams] = useSearchParams();
  const { partner } = usePartnerStore();
  const nextUrl = getNextUrl(searchParams, partner);
  const getStartedUrl = buildGetStartedUrl(nextUrl);

  const trackSignup = () => {
    trackSignUpButtonClicked(
      registerButtonTitle ?? 'Get started for free',
      'footer-pitch',
      title,
      title,
    );
  };

  return (
    <section className={container}>
      <H2 className={title}>
        <div className={classNames(glow, glowAlways)} />
        Get your crypto taxes done
      </H2>
      {discountText && <span className={subtitle}>{discountText}</span>}
      {toggleModal ? (
        <Button className={getStartedButton} onClick={toggleModal}>
          {registerButtonTitle ?? 'Get started for free'}
        </Button>
      ) : (
        <a
          className={classNames(
            button({ variant: 'primary', size: 'regular' }),
            getStartedButton,
          )}
          href={getStartedUrl}
          onClick={trackSignup}
        >
          {registerButtonTitle ?? 'Get started for free'}
        </a>
      )}
    </section>
  );
};
