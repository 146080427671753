import { trackSignUpButtonClicked } from '@/common/analytics';
import { CBO_PROMO_KEY } from '@/pages-react/CoinbaseOnePromotionPage/CoinbaseOnePromotionPage/constants';
import { Button, H1, URLS, isClient, useSearchParams } from '@cointracker/ui';
import { button } from '@cointracker/ui/src/components/base/Button/Button.css';
import classNames from 'classnames';
import { getStartedButton, glow, glowAlways } from '../Landing.css';
import { usePartnerStore } from '../partnerStore';
import { buildGetStartedUrl, getNextUrl } from '../utils';
import {
  ctaSubtext,
  discountText as discountTextStyle,
  glowContainer,
  heroCTA,
  heroTitle,
  subtextContainer,
  subtextContainerFlip,
} from './Hero.css';
import { HeroPartnerLogo } from './HeroPartnerLogo';
import { HeroSection } from './HeroSection';

interface HeroProps {
  title?: string;
  subtitle?: string;
  discountText?: string;
  partner?: string;
  registerButtonTitle?: string;
  toggleModal?: () => void;
}

const DEFAULT_TITLE = 'Crypto taxes done right';
const DEFAULT_SUBTEXT = `Connect your Coinbase account, exchanges, wallets, and DeFi activity, and get your optimized tax report in
minutes.`;
const DEFAULT_BUTTON_TITLE = 'Get started for free';

export const Hero = ({
  title,
  subtitle,
  discountText,
  toggleModal,
  registerButtonTitle,
}: HeroProps) => {
  const [searchParams] = useSearchParams();
  const { partner } = usePartnerStore();
  const nextUrl = getNextUrl(searchParams, partner);
  const hasCBOPromoCode = isClient() && localStorage.getItem(CBO_PROMO_KEY);
  const getStartedUrl = buildGetStartedUrl(
    hasCBOPromoCode ? URLS.COINBASE_ONE_PROMOTION : nextUrl,
  );

  const trackSignup = () => {
    trackSignUpButtonClicked(
      registerButtonTitle ?? DEFAULT_BUTTON_TITLE,
      'hero',
      title ?? DEFAULT_TITLE,
      title ?? DEFAULT_TITLE,
    );
  };

  return (
    <HeroSection>
      <div className={heroCTA}>
        <HeroPartnerLogo partner={partner?.slug} />
        <div className={glowContainer}>
          <H1 className={heroTitle}>{title ?? DEFAULT_TITLE}</H1>
          <div className={classNames([glow, glowAlways])} />
        </div>
        <div
          className={classNames(
            subtextContainer,
            partner?.slug === 'coinbaseone' ? subtextContainerFlip : '',
          )}
        >
          <p className={ctaSubtext}>{subtitle ?? DEFAULT_SUBTEXT}</p>
          {discountText && <p className={discountTextStyle}>{discountText}</p>}
        </div>
        {toggleModal ? (
          <Button
            className={getStartedButton}
            onClick={() => {
              trackSignup();
              toggleModal();
            }}
          >
            {registerButtonTitle ?? DEFAULT_BUTTON_TITLE}
          </Button>
        ) : (
          <a
            className={classNames(
              button({ variant: 'primary', size: 'regular' }),
              getStartedButton,
            )}
            href={getStartedUrl}
            onClick={trackSignup}
          >
            {registerButtonTitle ?? DEFAULT_BUTTON_TITLE}
          </a>
        )}
      </div>
    </HeroSection>
  );
};
