import { CBO_PROMO_KEY } from '@/pages-react/CoinbaseOnePromotionPage/CoinbaseOnePromotionPage/constants.ts';
import {
  Button,
  Modal,
  ModalBody,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  URLS,
  isClient,
} from '@cointracker/ui';
import { buildGetStartedUrl } from '../utils';
import { promoFooter, promoFooterButton } from './CoinbaseOneModal.css';

interface CoinbaseOneModalProps {
  toggleModal: () => void;
  isOpen: boolean;
}

export function CoinbaseOneModal({
  toggleModal,
  isOpen,
}: CoinbaseOneModalProps) {
  // TODO:
  // const { navigate } = useNavigate();
  const navigate = (url: string) => {
    window.location.href = url;
  };

  const hasCode = isClient() ? localStorage.getItem(CBO_PROMO_KEY) : false;
  const navigationURL = hasCode
    ? buildGetStartedUrl(URLS.COINBASE_ONE_LANDING)
    : URLS.EXTERNAL_COINBASE_ONE_BOUNCE_LINK;
  const description = hasCode
    ? `Looks like you got here from a Coinbase One Account! Sign up below to get started and claim your free Base plan`
    : `You will need to confirm your Coinbase One account in order to be eligible for the promotion. Please click the
  link below to be redirected to Coinbase One so we can verify that you are a Coinbase One Member.`;

  return (
    <Modal open={isOpen} onOpenChange={toggleModal}>
      <ModalHeader title="Coinbase One 🤝 CoinTracker" />
      <ModalBody>
        <ModalDescription>{description}</ModalDescription>
        <ModalFooter className={promoFooter}>
          <Button
            className={promoFooterButton}
            variant="ghost"
            onClick={toggleModal}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className={promoFooterButton}
            onClick={() => {
              navigate(navigationURL);
              toggleModal();
            }}
          >
            {hasCode
              ? 'Get started with a free plan'
              : 'Verify Coinbase One Membership'}
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}
